// src/App.js
import React from "react";
import { motion } from "framer-motion";
import popcorn from "./assets/images/popcorn.png";
import "./App.css";

const App = () => {
  return (
    <div className="App under-construction">
      <div className="construction-container">
        <motion.h1
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          🚧 Site Under Construction 🚧
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        >
          We're working hard to bring you an amazing experience. Please check
          back soon!
        </motion.p>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <img
            src={popcorn}
            alt="Construction Illustration"
            className="construction-image"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default App;
